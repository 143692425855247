.tsr {
    display: flex;
    color: black;
    background-color: white;
    flex-direction: row;
    position: relative;
    height: fit-content;

    justify-content: center;
    align-items: center;

}
/* .separator {
    display: block;
    position: relative;
    height: 3em;
    width: 100%;
    background-color: purple;
} */

.titleBox {
    display: flex;
    flex-direction: column;
    color: black;
    background-color: white;
    position:relative;
    flex:1;
    justify-content: center;
    align-items: center;
   margin:0;
   
   
   
}
.doubleImage {
    display:flex;
    flex-direction:row;
}

.titleBox img {
    display: flex;
    object-fit: fill;
   border-radius: 10px;
    margin: auto;
    /* background-color: red; */
    min-width: 80%;
}
.titleBox h1{
    color: inherit;
    margin: 20px;
 font-size: 30px;
 text-align: inherit;
 text-align: center;
}

.titleBox h2{
    color: inherit;
    margin: 20px;
    font-size: 20px;
}
.titleBox h3{
    color: inherit;
    margin: 20px;
    font-size: 20px;
}

.titleBox p {
    color: inherit;
    margin: 20px;
    font-size: 20px;
}

.explainBox {
    display: flex;
    flex-direction: column;
    color: black;
    background-color: white;
    position: relative;
    flex:1;
    /* align-items: center; */
    justify-content: center;
margin:20px;

}
.explainBox h2 {
    font-size: 28px;
}

.explainBox p{
    color: black;
    margin: 0;
}


@media screen and (max-width:920px)
  {

    h1 {
        font-size: 30px;
       
    }
    h2,h3,p {
        font-size: 20px;
    }

    .tsr {
        position: relative;
        flex-direction: column;
        height: fit-content;
    }
    .titleBox h1{
        margin-left: 10px;
    }

  }

  @media screen and (max-width:600px)
  {
.titleBox h1{
    margin: 10px;
}
  }

  @media screen and (max-width:600px)
  {
    .doubleImage {
        flex-direction: column;
    }
  }