.innerD {
    display: flex;
    justify-content: center;
    align-items: center;
 width:100%;
/* flex:2;
padding: 3em; */
 
 object-fit: cover;
     }

    .app__backgroundVideo {
        display: flex;
        width: 100%;
        object-fit: cover;
    }