.gridRowContainer {
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
gap:2em;
row-gap: 4em;
padding: 10px;
height: fit-content;
  
}

.gridObject {
    display: flex;
    flex-direction: row;
    flex:1;
    background-color: white;
   min-height: fit-content;
    /* max-height: 280px; */
    min-width: 200px;
    max-width: 280px;
    height: fit-content;
    width:100px;
    justify-content: center;
    position:relative; 


  text-align: center;
}

.gridObject h2 {
    color: black;
    margin-top: 20px;
    /* margin-bottom: auto; */
    min-width: 100px;
    max-width: 200px;
    text-align: center;
    margin-bottom: 10px;
    font-size: 28px;
    margin-left: auto;
    margin-right: auto;
    position:relative;
}
.gridObject h1 {
    color: black;
    margin-top: 40px;
    margin-bottom: auto;
    min-width: 100px;
    max-width: 200px;
    text-align: center;
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 800;
    margin-left: auto;
    margin-right: auto;
    position:relative;
}

.gridObject h3 {
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: auto;
    min-height: 40px;
    max-height: 60px;
    min-width: 100px;
    max-width: 140px;
    text-align: center;
    position:relative;
}

.gridObject img {
    display: flex;
    object-fit: contain;
   border-radius: 10px;
    margin: auto;
    min-height: 100px;
    max-height: 140px;
    min-width: 100px;
    max-width: 140px;
    position:relative;
    
}