.vidSection{
    display: flex;
    position: relative; 
    flex:1;
}

.vidSectionLeft{
    display: flex;
    position: relative; 
    flex:1;
}
.separator {
    display: block;
    position: relative;
    height: 4em;
    width: 100%;
    background-color: white;
}
.vidsectextbits {
    display: flex;
    background-color: white;
    color: black;  
    flex:2;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: justify;
}

.vidsectextbits h1{
font-size: 30px;
color: black;
margin: 10px;
text-align: center;
}


.vidsectextbits h2{
    font-size: 28px;  
    color: black;
    margin: 10px;
    
}
.vidsectextbits h3{
    font-size: 20px;  
    color: black;
    margin: 10px;
    
}
.vidsectextbits p{
    font-size: 20px;  
    color: inherit;
    margin: 10px;
    margin-left: 20px;
    margin-right: 20px;
}
.videoHolder {
    display: flex;
    flex:3;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    background-color: white;
    z-index: 900;
    position: relative;
    padding: 20px;
}

.videoHolder video {
border-radius: 10px;
    position: relative;
}

@media screen and (max-width:1020px)
  {
    .vidSection{
flex-direction: column;
    }

    .vidSectionLeft {
        flex-direction:column-reverse;
    }
  }

  @media screen and (max-width:600px)
  {
    
h1 {
    font-size: 30px;
}

h2 {
    font-size: 16px;
}
h3 {
    font-size: 16px;
}
p {
    font-size: 16px;
}
.vidsectextbits {
    text-align: left;
}
  }
