.casestudy-container {
    /* background: url('../../public/images/img-home.jpg') center center/cover no-repeat; */
    background-color: var(--secondary);
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2); */
    object-fit:cover;
 
  padding: 60px;
}

.casestudy-inset {
    display: flex;
    background-color: lightgray;
    /* margin:40px; */
    border-radius: 10px;
    width: 90%;
    
    
}
.casestudy-pic {
    display: flex;
    /* overflow: hidden; */
    flex:2;
   object-fit: cover;
   border-radius: 10px 0 0 10px ;
  align-items: center;
 
}
.casestudy-pic img{
    max-width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px ;
  
}

.casestudy-text {
    color: black;
    display: flex;
    flex-direction: column;
    margin: 20px;
    flex: 1;
  
}

.casestudy-text p{
    color: black;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.casestudy-text h3{
    color: red;
    display: flex;
    margin-top: 20px;
}
.casestudy-text h1{
    color: black;
    display: flex;
    margin-top: 20px;
    font-size: 40px;
    margin-left: 0;
    margin-right: 0;
}

.casestudy-btns {
    display: flex;
    margin: 20px;
    justify-content: end;
}

.quote-container {
    background-color: var(--secondary);
width: 100%;
padding-left:20%;
padding-right:20%;
    justify-content: center;
    align-items: center;
    text-align: center;
   display: block;
}

.quote-container p {
    background-color: var(--secondary);

    margin: 60px;
}

@media screen and (max-width:480px) {
    .casestudy-container {
       padding: 0;
    }
    .casestudy-text p{
font-size: 16px;
    }
    
    .casestudy-text h3{

        margin-top: 12px;
    }
    .casestudy-text h1{
        color: black;
        display: flex;
        margin-top: 18px;
        font-size: 30px;
    }

    .quote-container p {
        margin: 10px;
        margin-bottom: 100px;
        margin-top: 100px;
        font-size: 16px;
    }

}
@media screen and (max-width:680px) {
    .casestudy-text h1{
        font-size: 30px;
    }
}


@media screen and (max-width:1220px) {
    .casestudy-container {
        padding-top: 0rem;
        height: 100%;
        justify-content: flex-start;
        
    }

    .casestudy-inset {
        display: flex;
        flex-direction: column;
        justify-content: center;
       
    }

    .casestudy-pic {
        align-items: center;
        border-radius: 10px 10px 0 0 ;
    }
    .casestudy-pic img{
        max-width: 100%;
        height: 100%;
        border-radius: 10px 10px 0 0 ;
      
    }
    .casestudy-text h1{
        margin: 20px 20px 0;
    }

    .casestudy-text p {
margin: 20px 20px 0;
    }
 

}


