.MultiDevices {
margin: 0;
padding: 0;
    background: black;
   
  }
 
  .MultiDevices  h1{
    margin: 0;
    color: red;
    padding: 0;
    padding-top: 40px;
    
    text-align:center;
    font-size: 40px;
    margin-bottom: 20px;
    /* margin-left: 60px; */
  }

  .MultiDevices  h3{
    margin: 0;
    color: red;
    padding: 0;
    padding-top: 40px;
    
    text-align:center;
    font-size: 28px;
    margin-bottom: 20px;
    /* margin-left: 60px; */
  }

  .multidev-ender h1{
    text-align:center;
    font-size: 40px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .multidev-ender h2{
    text-align:center;
    font-size: 28px;
    color: black;
    margin-bottom: 20px;
    margin-right: 20px;
    padding-top: 20px;
  }

  .MultiDevices   video {
display:flex;
background-color: black;
position: relative;
z-index: 0;
}

.md-container{
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-left: 8em;
    margin-right: 8em;
    /* box-shadow: rgba(0, 0, 0, 0.321) 1px 0 10px; */
}

.md-container h1 {
  margin:0;
}
.fullWrapper{
display: flex;
flex-direction: row;
width: 100%;
/* max-height: 360px; */
margin: 0;
background-color: black;
justify-content: center;
align-items: center;
overflow:hidden;
margin-top: 15%;
margin-bottom: 15%;
}

.imageCont{
    width: 50%;
    display: flex;
    overflow: cover;
    justify-content: center;
    align-items: center;


}

.imageCont img{
/* width: 50%; */
max-width: 100%;
}
.VidCont {
    
    display: flex;
    width:50%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    background-color: black;
  

}

.textoverlay{
    display: flex;
    /* position: absolute; */
    z-index: 1;
    justify-content: center;
    align-items: center;
}

.textoverlay h1{
    display: flex;
    font-size: 40px;
   color: white;
   text-align: center;
   justify-content: center;
   align-items: center;
   /* text-shadow: rgba(0, 0, 0, 0.321) 1px 0 10px; */
}
.multidev-ender{
    /* margin-top: 30px; */
background-color:white;
}

.multidev-ender h1 {

    font-size: 60px;
}

.simPic {
    display: flex;
align-self: center;

    max-height: 100%;
    border-radius: 10px;
    overflow: hidden;
    
  }



.specPic__item {
  background-color: transparent;

}

.specPic__item__info{
  background-color: transparent;
}

.simpic-container {

   display: flex;
    justify-content: center;
   max-width:50%;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
      max-height: 500px;
      overflow: hidden;
      border-radius: 10px;
    
    
}

.lastcomment {
    display: flex;
    justify-content: center; 
    margin-top: 20px;
    padding: 40px;
    background-color: white;
}

@media screen and (max-width:920px) {

  .MultiDevices {
    padding-top: 80px;
  }
}

@media screen and (max-width:600px) {
.md-container {

  margin-left: 0;
  margin-right: 0;
}
    .MultiDevices  h1{

        font-size: 30px;
        /* margin-left: 10px; */
       
      }

      .MultiDevices  h3{
margin-left: 0;
      }

      .textoverlay h1{
        font-size: 30px;
      }

      .multidev-ender h2{
font-size: 28px;
padding-top: 20px;
      }

    .multidev-ender h1 {

        font-size: 30px;
    }
    .lastcomment {
        display: flex;
        justify-content: center; 
        margin-top: 16px;
        font-size: 16px;
    }

    .simpic-container {
        margin-top: 40px;
    }
.fullWrapper{
  flex-direction: column;
}





}