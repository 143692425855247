.topPic {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    height: 100vh;
  
  }

  .topPic  h2 {

color: red;
font-size: 30px;
z-index: 100;
position:relative;
  }

  .topPic  h1 {
    margin-top: 30px;
    color: white; 
    font-size: 70px;
   margin-left: 0px;
    text-shadow: black;
    z-index: 100;
    position:relative;
    margin: 10px;
    margin-bottom: 40px;
    
  }
  .topPic  h2 {
    font-size: 28px;
  }
  .topPic  h3 {
    margin-top: 20px;
        color: white;
        z-index: 100;
        position:relative;
        font-size: 20px;
       font-weight: 400;
  }

  topPic  p {
  font-size: 20px;
  }

  .imageOverlay {
    width: 100%;
  
    z-index: 0;
    position: absolute;
  }
  .TPtextbits {
    padding: 100px;
    width: 50%;
  }

  @media screen and (max-width:1220px)
  {
    .TPtextbits {
        padding: 60px;
        width: 60%;
      }
  }

  @media screen and (max-width:800px)
  {
   
    .TPtextbits {
      padding: 40px;
      width: 100%;
    }
   
    .topPic  h2 {

      color: red;
      font-size: 16px;
      z-index: 100;
      position:relative;
        }

        .topPic  h1 {
          margin-top: 24px;
          color: white; 
          font-size: 30px;
         margin-left: 0px;
          text-shadow: 2px 2px 6px black;
          z-index: 100;
          position:relative;
          
        }
      
        .topPic  h3 {
        
              color: white;
              z-index: 100;
              position:relative;
              font-size: 16px;
              text-shadow: 2px 2px 6px black;
        }

       

  }