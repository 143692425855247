.trusted-container {
    background: url('https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/pillars.jpg') center center/cover no-repeat; 
    height: 50vh;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.logos {
    
    display: flex;
    flex-direction: row;
    
    object-fit: 'cover'/contain;
    
}
.logos-wrapper {

    display: flex;
    margin: 10px;
    width: 90%;
    object-fit: 'cover'/contain;
    justify-content: center;
}

.logo-holder {
   
    display: flex;
    margin: 10px;
    object-fit: 'cover'/contain;
}
.logo-holder img{
border-radius: 10px;
}


@media screen and (max-width:920px) {
    .trusted-container {
      
        height: 80vh;
        justify-content: flex-start;
        object-fit:fill;
        overflow: visible;
    }

.logos-wrapper {

    display: flex;
    flex-direction: column;
   align-items: center;
}

    .logos {
        display: flex;
        flex-direction: row;
        margin: 5px;
    }

    /* .logos img {
        width: 70%;
        height: 70%;
    } */
    
}

@media screen and (max-width:480px) {
    .logos {
        flex-direction: column;
        overflow: hidden;
        align-items: center;
        justify-content: center;

    }

    .logos img {
        max-height: 70px;
        padding: 5px;
    }
}