.maincontainer {
display: flex;
flex-direction: row;
background-color: white;
height:fit-content;
justify-content: space-around;
flex-wrap: wrap;
}

.column {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 600px;
    height:fit-content;
    position: relative;
    justify-content: center;
    margin-bottom: 4em;
}

.column-title-box {
    display: flex;
    justify-content: space-between;
    margin: 20px; 
    background-color: white;
    /* flex-wrap: wrap; */
}
.column-title-box h2{
    display: flex;
    color: red;
    margin: 20px;
    background-color: white;
    flex:5;
}
.svg-box {
    display: flex;
    background-color: white;
    flex:2;
    justify-content: flex-end;
    align-items: start;
}

.svg-box img {
    display: flex;
    background-color: white;
   flex-grow: 2;
}
.column-image {
    display: flex;
    justify-content: center;
    position: relative;
}
.column-image img{
    display: flex;
    justify-content: center;
    margin:20px;
    width: 70%;
    object-fit: contain;
    position: relative;
}
.column-text {
    display: flex;
    justify-content: center;
    position: relative;
    background-color: white;
    width:70%;
    margin-left: auto;
    margin-right: auto;
}

.column-text  p {
    color: black;
    margin-top: 0;
    margin-left: 20px;
    margin-right: 20px;
    text-align: justify;
}

@media screen and (max-width:680px) {

    .column-text  p {
        color: black;
        margin-top: 0;
        margin-left: 10px;
        margin-right: 10px;
        text-align: left; 
    }

    .column-text {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        background-color: white;
        width:90%;
    }
    .column-image img {
        width:90%;
    }
    .column {
        display: flex;
        justify-content: center;
        background-color: white;
        flex-direction: column;
    }
}
