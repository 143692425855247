.cards {
    padding: 4rem;
    background: #fff;
    display:flex;
    justify-content: center;
    flex-direction: column;
  }
  .sectionTop {
    margin: 40px;
  }
  .cards > h1 {
    display:flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    text-align: center;
    color: black;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
  
  }
  .cards > h2 {
    margin-top: 10px;
    text-align: center;
    color: black;
    font-size: 28px;
  }
  .cards > h3 {
    margin-top: 1em;
    text-align: center;
    color: red;
    font-size: 20px;
  }
  ul {
    list-style: none;
  }

  a:-webkit-any-link {
    text-decoration: none;

  }

  h5 {
    text-align: center;
    color: red;
    font-size: 20px;
 text-decoration: none;
  }
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
    
  }
  
  .cards__item {
    /* display: flex; */
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
   
  }

 
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;

  
    
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius:6px;
    object-fit: contain;
   
    
  }

  .cards__item__pic-wrap img{
    width: 200%;
object-fit: fill;
  }

  .specPic__container{
display: flex;
justify-content: center;

  }

.specPic__wrapper >ul {

  display: flex;
  flex-direction: row;
  margin:  2rem 0 2rem 0;
  justify-content: center;
}

.specPic__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
}


  .specPic__item {
    /* display: flex; */
    flex: 1;
    margin: 0 2rem;
    border-radius: 10px;
    background-color: white;
   display: flex;
   flex-direction: column;
   justify-content: center;
  }
  
  .specPic__item__link {
    display: flex;
    /* flex-flow: column; */
    width: 100%;
   
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    
  }
  
  .specPic__item__pic-wrap {
    position: relative;
    width: 100%;
    /* padding-top: 67%; */
    overflow: hidden;
    border-radius:6px;
    display: flex;
    max-height: 15rem;
    justify-content: center;
    
    
  }
  .specPic__item__img {
    /* position:absolute; */
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width:fit-content;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
    
  }

  .specPic__item__vid {
    /* position:absolute; */
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width:fit-content;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
    
  }
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    top: 0px;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    background-color: red;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position:absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width:fit-content;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: contain;
    transition: all 0.2s linear;
    
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
    text-decoration:none !important;
       
       
  }

  .cards__item__info  a{
    text-decoration:none ;
}
  
  .cards__item__text {
    color: black;
    font-size: 20px;
    line-height: 24px;
    text-decoration:none !important;
  }
  
  .cards__item__text h5 {
    text-decoration:none 
  }
.cards__items {
  padding: 0;
}

@media screen and (max-width: 480px) {
  .cards > h1 {
    margin-top: 10px;
    font-size: 30px;
  }
  .cards {
    padding: 5px;
    padding-top: 20px;
  }
  
 .cards h2 {
 font-size: 28px;
 margin-left: 1em;
 margin-right: 1em;
}

}
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }