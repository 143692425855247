.PGgridRowContainer {
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
gap:2em;
padding: 100px;
  
}

.PGgridObject {
    display: flex;
    flex-direction: row;
    flex:1;
    background-color: white;
   /* min-height: 220px;
    max-height: 280px;
    min-width: 200px;
    max-width: 280px; */
    height: fit-content;
    /* width:100px; */
    justify-content: center;


  text-align: center;
}

.PGgridObject h2 {
    margin-top: auto;
    margin-bottom: auto;
    min-width: 100px;
    max-width: 200px;
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
}

.PGgridObject h3 {
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: auto;
    min-height: 40px;
    max-height: 60px;
    min-width: 100px;
    max-width: 140px;
    text-align: center;
}

.PGgridObject img {
    display: flex;
    object-fit:contain;
   border-radius: 10px;
    margin: auto;
    min-height: 220px;
    max-height: 280px;
    min-width: 200px;
    max-width: 280px;
    
}