.businessModel {
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.businessModel h1 {
margin: 0;
margin-left: auto;
margin-right: auto;
padding: 20px;
color: black;
text-align: center;
}