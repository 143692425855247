 :root {
    --primary : white;
 }

 .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
   
 }

 .btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
    padding: 8px 20px;
 }

 .btn--outline {
    background-color: transparent;
    color: var(--primary);
    border: 1px solid var(--primary);
    padding: 8px 20px;
    /* margin: 20px; */
    transition: all 0.3s ease-out;
   
 }

 .btn--outline--white {
   background-color: transparent;
   color: white;
   border: 1px solid white;
   padding: 8px 20px;
   /* margin: 20px; */
   margin-left: 0;
   margin-right: 0;
   transition: all 0.3s ease-out;
  
}

 .btn--medium {
    padding: 8px 20px;
    font-size: 20px;
 }

 .btn--large {
    padding: 12px 26px;
    font-size: 20px;
 }

 .btn--medium:hover, .btn--large:hover {
background-color: var(--primary);
color: #242424;
transition: all 0.3s ease-out;

 }

 @media screen and (max-width:480px) {
   .btn--outline {
      margin-left: 0;
   margin-right: 0;
   }
 }