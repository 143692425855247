.fromTrainee__container {
    background-color: var(--secondary);
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit:cover;
  /* padding: 60px;
  padding-top:120px ; */


  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2); */
  object-fit: contain;

}


.fromTrainee__picFrame {
    /* background: url('./../../public/images/confidenceInTrainees.jpg') center center/cover no-repeat; */
    width: 50%;
margin: 40px;
border-radius: 10px;
}

.fromTrainee__textcontiner h1  {
    color:  white;
    font-size: 40px;
    margin: 20px;
}

.fromTrainee__textcontiner {
    margin: 100px;
}

.fromTrainee__container h2  {
    color:  white;
    font-size: 28px;
    margin: 20px;
}

.fromTrainee__container h3  {
    color:  white;
    font-size: 20px;
    margin: 20px;
    font-weight: lighter;
    text-align: justify;
}

.fromTrainee__container p{
    color:  red;
    font-size: 20px;
    margin: 20px;
   
}

.fromTrainee__wrapper {
    display: flex;
margin: 40px;
}

.fromTrainee-btns {
    display: flex;
    margin: 20px;
    width: 100%;
   
   align-items: flex-end;
    justify-content: flex-end;
}
.fromTrainee__pillars {
display: flex;
justify-content: space-around;
margin: 20px;
width: 100%;
}

.fromTrainee__Pillar {
    margin:20px;
    text-align: center;
    text-align: justify;
}

@media screen and (max-width: 1200px){

    .fromTrainee__textcontiner {
        margin:40px;
    }
}
@media screen and (max-width:920px) {

    .fromTrainee__container {
        /* padding-top: 35rem; */
        display: flex;
        align-items: center;
        height: fit-content;
        padding-top: 0rem;
        flex-direction: column;
      
   
}

.fromTrainee__wrapper {

    display: flex;
    flex-direction: column;
margin: 40px;
justify-content: center;
align-items: center;
}

.fromTrainee__picFrame {
    display: flex;
    justify-content: center;
    margin: 50px;
    width: 100%;
    
}

.fromTrainee__textcontiner {
    margin:20px;
}
}

/* 
at 480px:

h1 = 30
h3 = 16
p = 16

*/
@media screen and (max-width: 480px) {

    .fromTrainee__textcontiner  h1  {
      
        font-size:30px;
    }
    
    .fromTrainee__container h2  {
        font-size:16px;
    }
    .fromTrainee__container h3  {
        font-size:16px;
    }

    .fromTrainee__container p  {
        font-size: 16px;
    }

}

