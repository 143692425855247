video {
    display: flex;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}
 .innerD {
display: flex;
justify-content: center;
align-items: center;
 }


.amo-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
    background-color: transparent;
  
}

.amo-container h1{
    margin-left: 20px;
    margin-right: 20px;
}

.amo-container p {
    margin-left: 20px;
    margin-right: 20px;
}

h1  {
    color:  white;
    font-size: 40px;
    margin-top: 20px;
    text-align: left;
    max-width: 1500px;
    /* margin-right:40px;
    margin-left: 40px; */
}

p{
    color:  white;
    font-size: 20px;
    margin-top: -100px;
   
}
.amo-btns-interimcontainer {
    display: flex;
    width: 100%;
 margin-top: 40px;
 max-width: 1500px;
 margin-right:100px;
    justify-content: flex-end;
    
}
.amo-container  .amo-btns {
    margin-top: 40px;
    background-color: rgba(0,0,0,0.7);
 
}

@media screen and (max-width: 960px){

    h1  {
    color:  white;
    /* margin-left: 20px; */
    font-size: 30px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

p{
    color:  white;
    font-size: 16px;
    margin-top: -100px;
    /* margin-left: 20px; */
}
}