.team {
    background-color: black;
    display: flex;
    flex-direction: row;
  }

  .team h1{
    color: aqua;
    font-size: 40px;
    margin: 20px;
  }

  .team h2 {
font-size: 28px;
    color: white;
    margin: 20px;
  }

  .team h3 {
    font-size: 20px;
  }

  .team p {
    font-size: 20px;
  }

.textsec{
  display: flex;
  flex:2;
  flex-direction: column;
 padding: 20px;
 padding-top: 100px;
 padding-bottom: 100px;
}

  .imgs-holder{
    display: flex;
    flex:3;
   padding: 20px;
   padding-top: 100px;
   padding-bottom: 100px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    gap: 1em;
  }
  .img-hold{
    display: flex;
    flex-direction: column;
  color: white;
  margin:2em;
    width: 200px;
    

  }

  .img-hold .name {
    color: aqua;
  }
  .img-hold .jobTitle {
    color: white;
  }

  .textbits {
    padding: 100px;
    width: 50%;
  }

.teamMemberInfo {
  
  border-radius: 10px;
  margin: 10px;
  margin-left: -30px;
  margin-right: -30px;
  padding: 10px;
  color:black;
  
}

  @media screen and (max-width:920px)
  {

  }

  @media screen and (max-width:600px)
  {
   
    .textbits {
      padding: 40px;
      width: 100%;
    }
   
    .topPic  h2 {

      color: red;
      font-size: 16px;
      z-index: 100;
      position:relative;
        }

        .topPic  h1 {
          margin-top: 24px;
          color: white; 
          font-size: 30px;
         margin-left: 0px;
          text-shadow: black;
          z-index: 100;
          position:relative;
          
        }
      
        .topPic  h3 {
              color: white;
              z-index: 100;
              position:relative;
              font-size: 16px;
        }

        .team {
          flex-direction: column;
        }
        .textsec {
          padding-bottom: 0px;
        }
        .imgs-holder {
          padding-top: 0px;
        }

  }