.whyUs__container {
    /* background: url('../../public/images/img-home.jpg') center center/cover no-repeat; */
    background-color: white;
    /* height: 100vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2); */
    object-fit: contain;
  
  
}
.p {
    text-align: justify;
}

.reference {
    text-align: end;
    font-style: italic;
    color: darkred;
}

.whyUs__picFrame {
    background: url('../../public/images/img-home.jpg') center center/cover no-repeat;
    width: 50%;
margin: 40px;
border-radius: 10px;
object-fit: cover;

}

.whyUs__container h1  {
    color:  black;
    font-size: 40px;
    margin: 20px;
}

.whyUs__container h2  {
    color:  black;
    font-size: 28px;
    margin: 20px;
}

.whyUs__container h3  {
    color:  red;
    font-size: 20px;
    margin: 20px;
    
    text-align: justify;
}

.whyUs__container p{
    color:  black;
    font-size: 20px;
    margin: 20px;
   
}

.whyUs__wrapper {
    display: flex;
margin: 40px;
}

.whyUs-btns {
    display: flex;
    margin: 20px;
    justify-content: end;
}
.whyUs__pillars {
display: flex;
justify-content: space-around;
margin: 20px;
width: 100%;
}

.whyUs__Pillar {
    margin:20px;
    text-align: center;
    text-align: justify;
}

.whyUs__textcontiner {
    margin: 100px;
}
@media screen and (max-width: 1200px){

    .whyUs__textcontiner {
        margin:40px;
    }
}

@media screen and (max-width:920px) {
    
    .whyUs__container {
        padding-top: 0rem;
        justify-content: flex-start;
}

.whyUs__textcontiner {
    margin:20px;
}

    .whyUs__wrapper {
        padding-top: 2rem;
        position: relative;
        display: flex;
        flex-direction: column;
    margin: 10px;
    background-color: lightgray;
    justify-content: center;
    align-items: center;

    }

    .whyUs__picFrame {
       display: flex;
        justify-content: center;
     width: 70%;
    margin: 10px;
    border-radius: 10px;
    }

    .whyUs__pillars {
        background-color: white;
        display: flex;
        flex-direction: column;
        position:relative;
        margin: 20px;
        width: 100%;
        }
        
        .whyUs__Pillar {
            display: flex;
            position: relative;
            flex-direction: column;
            margin:20px;
            text-align: center;
            text-align: justify;
        }

}





.trusted {
    background: url('../../public/images/pillars.jpg') center center/cover no-repeat; 
    height: 40vh;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logos {
    height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo-holder {
    display: flex;
    margin: 40px;
   
}
/* 
at 480px:

h1 = 30
h3 = 16
p = 16

*/
@media screen and (max-width: 480px) {
    .whyUs__container h1  {
        font-size: 30px;
    }
    .whyUs__container h2 {
        font-size: 16px;
    }
    .whyUs__container h3 {
        font-size: 16px;
    }
    .whyUs__container p {
        font-size: 16px;
    }

    
}