
body {
    --primary : red;
    --secondary: rgba(20,20,20);
    background-color: black;
 }
* {
    box-sizing: border-box;
    margin: 0;
background-color: transparent;
    font-family: 'PT Sans', sans-serif;
  }
.svgHolder {

  background-color: white;
}

.del-container{
  display: flex;
  position: relative;
  background-color: white;
  justify-content:center;
  flex-direction: column;
 
}

.del-container h1{
  text-align: center;
  font-size: 40px;
  color: black;
}
.del-para {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  height:fit-content;
  margin-left: 5em;
  margin-right: 5em;
}

.del-para h2 {
color:red;
margin:10px;
padding: 10px;
}

.del-para p {
  color:black;
  margin:10px;
padding: 10px;
  }


  .separator {
    display: block;
    position: relative;
    height: 3em;
    width: 100%;
    background-color: white;
}
.pp h2{
  margin-left:40px
}
.pp h4{
  text-align: center;
}

  .home,
  .amoTutor,
  .amoCourse,
  .amoSupport,
  .how,
  .caseStudies,
  .about,
  .partners,
  .deletedata,
  .signUp {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    
  }
  
  h3 {
    line-height: 2em;
  }
  .amoTutor {
    background-image: url('../public/images/img-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    font-size: 100px;
  }
  
  .amoSupport {
    background-image: url('../public/images/img-1.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: white;
    font-size: 100px;
  }
  
  .amoCourse {
    background-image: url('../public/images/img-8.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    font-size: 100px;
  }

  .about {
    background-image: url('../public/images/img-1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    font-size: 100px;
  }

  @media screen and (max-width:600px) {

    .del-para{
      margin-left: 0;
      margin-right: 0;
    }
  }
 
