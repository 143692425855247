.footer-container {
    background-color: red;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;


}

form {
    
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    width: 100%;
}
.input-areas {
    display: flex;
    flex-direction: column;
    width:20em;
    justify-content: center;
    margin: 0;
    gap:1em;

}

.input-areas textarea {
    background-color: white;margin: 0;
    position: relative;
    border-width: 0;
    border-radius: 5px;
    min-height: 100px;
    height: fit-content;
    width: 100%;
    padding: 8px 0px;
    padding-left: 10px;
    padding-right: 10px;
}

::placeholder {

    padding-left: 20px;  
  }

.footer-subscription > p {
    color: white;
    margin: 20px;
position: relative;
}
.footer-subscription > h1 {
    color: white;
    margin: 20px;
position: relative;
}

.footer-input {
    background-color: white;
    margin: 0;
position: relative;
border-radius: 5px;
border-width: 0;
height: 40px;
padding-left: 10px;
padding-right: 10px;
/* padding: 8px 20px; */
}


.footer-subscription {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color:white;

}

.footer-links {
    background-color: var(--secondary);
    width:100%;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-items: center;
    color:white


}

.footer-link-wrapper {
display: flex;
justify-content: space-evenly;
}

.footer-link-items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
   
    box-sizing: border-box;
    justify-content: center;

}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items > h2 {
color: white;
margin-left: 20px;
}

.footer-item > p {
    color: lightgray;
    font-size: 20px;
    }
.footer-link-items a {

    color: white;  
    text-decoration: none;
}

.footer-link-items a:hover {

    color: red;  
    text-decoration: none;
    transition: 0.3s ease-out;
}

.social-media {
    background-color: var(--secondary);
    width:100%;
  
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    color:white


}

.social-logo {
    color: white;
    font-size: 28px;
    margin: 16px;
}


.social-icon-link {
    color: white;
    font-size: 28px;

}

.social-media-wrap {
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;


}

.websight-rights {
    margin:16px;
}

.social-icons {

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;

}

.footer-item{
    display: flex;
    flex-direction: column;
    margin: 40px;
}

.footer-item p{
    display: flex;
    margin-top: 20px;

}


.fl-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}

.prpo {
    background-color: #141414;
}
.toast-warning {
    background-color: blueviolet;
}

@media screen and (max-width:820px) {
    .footer-links{
        padding-top: 2rem;
    }

    .footer-input {
        width:100%;
    }

    .btn {
        width:100%;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }
    .social-media-wrap {
        flex-direction: column;
    }
.footer-link-items {
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.footer-link-items > h3{
    text-align: 'center';
}
.footer-item h3 {
    text-align: 'center';
}

}
